@import '../../styles/colors';
@import '../../styles/variables';

.container {
	position: relative;
}

.richText {
	div:nth-of-type(1) {
		border-color: $border-color;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		filter: drop-shadow(0px 2px 5px $border-color);
		-webkit-filter: none;
		background-color: $base-white-color;

		svg {
			path,
			line,
			rect {
				stroke: $light-color;
			}
		}
	}

	div:nth-of-type(2) {
		min-height: 100px;
		border: 1px solid $border-color;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		font-size: 15px;
		color: $text-color;

		div:nth-of-type(1) {
			min-height: 100px;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			border-bottom-left-radius: 5px;
			border-bottom-right-radius: 5px;
			filter: drop-shadow(0px 2px 5px $border-color);
			-webkit-filter: none;
		}

		div:nth-of-type(3) {
			padding: 5px 8px;
			position: absolute;
			z-index: $z-tooltip;
			left: -13px !important;
			font-size: 15px;

			&::before {
				content: 'URL:';
			}

			a {
				max-width: 100px;
			}
		}
	}

	strong {
		font-weight: bold;
	}

	em {
		font-style: italic;
	}

	ul {
		padding-left: 0;
		li {
			padding-left: 12px;
			list-style-type: disc;
			list-style-position: inside;
		}
		li:not(.ql-direction-rtl) {
			padding-left: 12px;
		}

		li::marker {
			margin: 0 8px 0px -1px;
		}
	}

	ul > li:before {
		content: none;
	}

	ol {
		padding-left: 0;
		li {
			padding-left: 12px;
			list-style-type: decimal;
			list-style-position: inside;
		}
		li:not(.ql-direction-rtl) {
			padding-left: 12px;
		}
	}
}

.error {
	position: absolute;
	width: 100%;
	bottom: -15px;
	left: 15px;
	font-size: 12px;
	color: $error-color;
}
