@import '../../styles/colors';
@import '../../styles/extends';

.template {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	height: 100%;
	width: 100%;
	padding: 30px;
	color: white;
	background-size: cover;
	background: grey url('../../assets/images/TemplateBg.png') center;
	text-align: center;
	filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.071));
	-webkit-filter: none;

	button {
		padding: 5px 10px;
		min-height: 15px;
		min-width: 100px;
	}
}

.templateRow {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.titleWrapper {
	position: absolute;
	top: 0;
	left: 0;
}

.title {
	font-size: 14px;
	color: $light-color;
	position: relative;
	top: -40px;
}

.question {
	font-size: 18px;
	color: $text-color;
	width: 100%;
	font-weight: bold;
	line-height: 22px;
}

.inputRow {
	display: flex;
	margin-top: 10px;
}

.buttonsContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}

.buttonWrapper {
	margin-top: 12px;
	width: 100%;
	button {
		width: 100%;
	}
}
