@import '../../styles/colors';
@import '../../styles/variables';

.container {
  width: 100%;
  padding: 50px;
}

.title {
  margin: 50px;
  text-align: center;
}

.error {
  margin: 50px;
  text-align: center;
  color: $error-color;
  white-space: pre-line;
  line-height: 200%;
}
