@import '../../styles/colors';
@import '../../styles/variables';

.container {
  width: 100%;
  padding: 50px;
}

.title {
  margin: 50px;
  text-align: center;
}

.subscriptionContainer{
  width: 90%;
  max-width: 450px;
  background-color: white;
  border-radius: $border-radius;
  padding: 20px;
  border: 1px solid $border-color;
  margin-left: auto;
  margin-right: auto;
}

.subscriptionBox {
  display: flex;
  flex-direction: row;
}

.subscriptionLevel {
  color: $text-color;
  display: flex;
  font-size: $fs-h4;
}

.role {
  margin-left: 10px;
  color: $primary-color;
  font-size: $fs-h4;
  font-weight: bold;
}

.button {
  width: 90%;
  max-width: 150px;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
}
