@import '../../styles/variables';
@import '../../styles/colors';
@import '../../styles/extends';

.titleWrapper{
	margin-bottom: 0;
}
.sceneTitle {
	color: $text-color;
	font-size: $fs-h3;
}

.icon {
	cursor: pointer;
	margin-left: 15px;
	margin-bottom: 1px;
	path {
		@extend %global-transition;
	}

	&:hover,
	&:active,
	&:focus {
		path {
			fill: $primary-color;
		}
	}
}

.titleInput {
	border: none;
	outline: none;
	background-color: transparent;
	color: $text-color;
	font-size: $fs-h3;
	margin-top: 0;
	padding: 0;
	max-width: 200px;
	@extend %secondary-font;
}

.titleWrapper {
	padding: 0 0 15px 0;
	display: flex;
	flex-direction: column;
	position: relative;
}

.zIndex {
	z-index: $z-navbar;
}

.label {
	font-size: 12px;
	margin-bottom: 10px;
	color: $text-color;
}

.checkboxWrapper {
	display: flex;
	align-items: center;
	margin-top: 5px;
	margin-bottom: 10px;

}

.checkboxLabel {
	display: inline-block;
	font-size: 15px;
	color: $text-color;
	margin-left: 5px;
}

.warning {
	font-size: 14px;
	text-align: center;
}
