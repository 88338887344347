@import '../../styles/colors';
@import '../../styles/extends';

.inputGroup {
	padding: 15px 0px;
	display: flex;
	flex-direction: column;
}

.inputToggle {
	margin-bottom: 10px;
	display: flex;
	position: relative;
	align-items: center;

	label {
		margin-bottom: 0px;
	}
}

.label {
	font-size: 12px;
	margin-bottom: 10px;
	color: $text-color;
}

.imageRow {
	display: flex;
}

.condition {
	color: $light-color;
	align-self: center;
	font-size: 12px;
	margin-left: 10px;
	max-width: 112px;
	//max-width: 120px; reduced for delete icon
}
.delete {
	align-self: center;
}
.deleteIcon {
	height: 20px;
	width: 16px;
	margin: -4px 0 0 0px;
	cursor: pointer;
	border-radius: 5px;
	background-color: $light-background-color;
	@extend %global-transition;
	path {
		fill: $grey;
	}
	&:hover,
	&:focus {
		path {
			fill: $primary-color;
		}
	}
}
