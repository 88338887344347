@import '../../styles/variables';
@import '../../styles/colors';
@import '../../styles/extends';

.inputGroup {
	padding: 15px 0;
	display: flex;
	flex-direction: column;
	position: relative;
}

.label {
	font-size: 12px;
	margin-bottom: 10px;
	color: $text-color;
}

.jsonUploadedBox {
	width: 100%;
	min-height: 70px;
	background: $bg-gray;
	border: 2px dashed #a6a7ad;
	border-radius: 8px;
	opacity: 1;
	display: inherit;
	font-size: 12px;
}
.jsonUploadedLabel {
	text-align: center;
	letter-spacing: 0;
	color: #3085ff;
	opacity: 1;
	padding: 20px;
	line-height: 20px;
	padding-right: 10px;
}

.doneIcon {
	margin-top: 7.5%;
	width: 25px;
	height: 25px;
}

.deleteIcon {
	margin-top: 7.5%;
	margin-left: 2%;
	width: 22px;
	height: 25px;
	cursor: pointer;
}
.uploadingContainer {
	width: 100%;
	height: 70px;
	background: $bg-gray 0 0 no-repeat padding-box;
	border: 2px dashed #a6a7ad;
	border-radius: 8px;
	font-size: 12px;
	opacity: 1;
}

.uploadFileLabel {
	text-align: left;
	letter-spacing: 0;
	color: #a6a7ad;
	font-size: 12px;
	opacity: 1;
	padding: 10px;
}
