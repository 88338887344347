@import '../../styles/colors';
@import '../../styles/variables';

.container {
  width: 100%;
  padding: 50px;
}

.title {
  margin: 50px;
  text-align: center;
}

.settingsContainer{
  width: 90%;
  max-width: 450px;
  background-color: white;
  border-radius: $border-radius;
  padding: 20px;
  border: 1px solid $border-color;
  margin-left: auto;
  margin-right: auto;
}

.language {
  display: flex;
  flex-direction: column;
}

.languageLabel {
  color: $text-color;
  margin-bottom: 10px;
  display: flex;
  font-size: $fs-base;
}
.languageSelect {
  width: 100%;
}

.subscriptionBox {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}
.subscription {
  display: flex;
  flex-direction: row;
}
.role {
  margin: 7px 20px 10px 10px;
  color: $primary-color;
  font-size: $fs-h4;
  font-weight: bold;
}
.subscriptionStatus {
  color: $text-color;
  margin: 9px 20px 0px 10px;
  display: flex;
  font-size: $fs-base;
}

.passwordBox {
  //margin: 20px 130px 0 0;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.emailOrBtnBox {
  height: 30px;
  width: 300px;
  display: flex;
  justify-content: flex-end;
}
