@import '../../styles/colors';
@import '../../styles/variables';
@import '../../styles/extends';

.wrapper {
	position: relative;
	// top: 30px;
	margin-top: 10px;
}

.checkboxWrapper {
	display: flex;
	align-items: center;
	padding-bottom: 10px;
}

.checkboxLabel {
	display: inline-block;
	font-size: 15px;
	color: $text-color;
	margin-left: 5px;
}

.codeEditor {
	border: 1px solid $input-box-border;
	width: 100%;
	max-width: 270px;
	word-wrap: break-word;
	font-size: $fs-base;
	border-radius: 8px;
	overflow: hidden;
}

.projectNumber {
	// copied from .fileExtension in FileUpload.module.scss
	text-align: left;
	font-size: 12px;
	letter-spacing: 0;
	color: #a6a7ad;
	opacity: 1;
	line-height: 1.5;
}
