@import '../../styles/colors';
@import '../../styles/variables';

.container {
	display: flex;
	height: 100vh;
	padding-top: 50px;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-image: url('../../assets/images/login-bg.png');
	background-position: center center;
	background-size: 40%;
	background-repeat: no-repeat;
	background-color: #edd7ac;
}

.textWrapper {
	top: 15%;
	text-align: center;
	max-width: 600px;
}

.logoPortrait{
	width: 265px;
	margin-bottom: 20px;
	height: auto;
}

.title {
	font-size: $fs-big;
	color: $black;
	font-weight: 300;
	margin-right: 50px;
	margin-left: 50px;
	text-align: center;
}

.noAccountContainer{
	position: absolute;
	bottom: 25px;
	text-align: center;
}
.noAccountLabel{
	font-size: $fs-h4;
	font-weight: 300;
	margin: 40px 0 15px 0
}
.link {
	color: $accent-color;
	font-weight: 300;
}
