@import '../../styles/colors';
@import '../../styles/extends';
@import '../../styles/media';

.card {
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: white;
	border-radius: 5px;
	filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.039));
	-webkit-filter: none;
	padding: 45px;
	margin-bottom: 0;

	&:not(:nth-child(3n + 3)) {
		margin-right: 35px;
	}

	@include mobile-down {
		width: 100%;
	}
}

.column {
	flex: 1;
	display: flex;
}

.imageColumn {
	flex: 0 47%;
}

.imageContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.image {
	width: 100%;
	height: auto;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}

.playIcon {
	position: absolute;
	cursor: pointer;
	width: 90px;
	height: 90px;

	path {
		&:first-of-type {
			opacity: 0.7;
			fill: #000000;
		}
	}

	&:hover,
	&:focus {
		path {
			&:first-of-type {
				@extend %global-transition;
				opacity: 1;
			}
		}
	}
}

.mobileIcon {
	display: none;
}

.textContainer {
	flex: 1;
	display: flex;
	flex-direction: column;
}

.header {
	display: flex;
	justify-content: space-between;
	position: relative;
}

.title {
	font-size: 32px;
	line-height: 36px;
	color: $text-color;
	font-weight: 600;
	width: 75%;

	@include mobile-down {
		width: 100%;
		white-space: pre-wrap;
		word-break: break-all;
	}
}

.viewCount {
	font-size: 16px;
}

.description {
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	margin-top: 20px;
	margin-bottom: 20px;
	color: $light-color;
	line-height: 20px;
}

.iconsRow {
	display: flex;
	align-items: center;

	@include mobile-down {
		top: 12px;
		right: 20px;
	}
}

.iconWrapper {
	background-color: red;
	@extend %global-transition;
	height: 35px;
	width: 30px;
	border-radius: 22px;
	&:hover {
		background-color: $primary-color;
		color: white
	}
}

.editIcon {
	background-color: $primary-color !important;
}

.editIcon,
.copyIcon,
.embedIcon,
.shareIcon,
.userIcon,
.analyticsIcon,
.deleteIcon {
	width: 35px;
	height: 35px;
	margin: 0 11px 25px 0;
	cursor: pointer;
	border-radius: 20px;
	background-color: $grey;
	@extend %global-transition;

	path {
		fill: white;
	}

	&:hover,
	&:focus {
		background-color: $primary-color;
		path {
			fill: white;

		}
	}
}

@include large-down {
	.card {
		flex: 0 510px;
	}
}

@include desktop-down {
	.card {
		flex: 0;
	}
}

@include tablet-down {
	.card {
		flex: 0;
	}
}

@include mobile-down {
	.textContainer {
		padding: 40px 25px;
	}

	.description {
		margin-top: 10px;
	}

	.column:last-of-type {
		display: none;
	}

	.mobileIcon {
		display: initial;
	}
}
