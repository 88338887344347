/**
 * ANIMATIONS
 */
@keyframes keyframe-fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@mixin animation-fade-in($delay) {
	animation-name: keyframe-fade-in;
	animation-duration: 0.25s;
	animation-timing-function: ease-in-out;
	animation-fill-mode: forwards;
	animation-delay: $delay;
	opacity: 0;
}
