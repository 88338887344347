@import '../../styles/colors';
@import '../../styles/variables';

.inputGroup {
	padding: 15px 0 0 0;
	display: flex;
	flex-direction: column;
	position: relative;

	audio {
	 max-width: 100%;
	}
}

.label {
	text-align: left;
	font: normal normal bold 15px/13px Ubuntu;
	letter-spacing: 0;
	color: #4d4f5c;
	opacity: 1;
	margin-bottom: 3%;
}

.imageRow {
	display: flex;
	flex-direction: column;
}

.condition {
	color: $light-color;
	align-self: center;
	font-size: 12px;
	margin-left: 10px;
	max-width: 120px;
}

.addAudioLabel {
	text-align: left;
	font: normal normal bold 15px/13px Ubuntu;
	letter-spacing: 0;
	color: #4d4f5c;
	opacity: 1;
	margin-bottom: 3%;
}

.uploadingContainer {
	width: 100%;
	height: 70px;
	background: $bg-gray 0 0 no-repeat padding-box;
	border: 2px dashed $border-color;
	//border-radius: $border-radius;
	font-size: 12px;
	opacity: 1;
}

.uploadFileLabel {
	text-align: left;
	letter-spacing: 0;
	color: #a6a7ad;
	font-size: 12px;
	opacity: 1;
	padding: 10px;
}

.percentage {
	width: 250px;
	height: 70px;
	text-align: center;
	letter-spacing: 0;
	color: #a6a7ad;
	opacity: 1;
	padding: 10px;
}

.audioUploadedBox {
	width: 100%;
	//min-height: 70px;
	background: $bg-gray;
	border: 2px dashed $border-color;
	border-radius: 8px;
	opacity: 1;
	display: inherit;
	font-size: 12px;
	position: relative;
	margin-top: 10px;
}

.audioUploadedLabel {
	text-align: center;
	letter-spacing: 0;
	color: #3085ff;
	opacity: 1;
	padding: 20px;
	line-height: 20px;
	padding-right: 10px;
	padding-left: 50px;
	max-width: 240px;
	word-wrap: break-word;
	text-transform: capitalize;
}

.doneIcon {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 20px;
	width: 22px;
	height: 22px;
}

.deleteIcon {
	width: 22px;
	height: 22px;
	cursor: pointer;
	position: absolute;
	right: 20px;
	top: 50%;
	transform: translateY(-50%);
}
