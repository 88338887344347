@import '../../styles/colors';
@import '../../styles/extends';
@import '../../styles/variables';

.answer {
	padding-bottom: 15px;
	padding-top: 15px;
	border-bottom: 1px solid $border-color;
	flex-grow: 1;
}

.answerInputContainer {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.answerLabel {
	font-size: 12px;
	color: $light-color;
	margin-bottom: 8px;
}

.answerGroup {
	display: flex;
	align-items: center;
	position: relative;
}

.minusIco {
	position: relative;
	top: 10px;
	margin-left: 15px;
	width: 22px;
	cursor: pointer;

	&:hover,
	&:focus {
		path {
			@extend %global-transition;
			fill: darken($error-color, 10%);
		}
	}
}

.inputGroup {
	margin: 15px 0;
	display: flex;
	flex-direction: column;
	position: relative;
}

.label {
	font-size: 12px;
	margin-bottom: 8px;
	color: $light-color;
}

.inputToggle {
	margin-bottom: 10px;
	display: flex;
	position: relative;
	align-items: center;

	label {
		margin-bottom: 0;
	}
}

.newTabToggle{
	margin-top: 20px;
	margin-left: 0;
	padding-left: 0;
}

.zIndex {
	z-index: $z-navbar;
}

.hr {
	border: 1px solid #f4f4f4;
	margin-top: 0;
	margin-bottom: 15px;

	&:last-of-type {
		display: none;
	}
}

.imageRow {
	display: flex;
}

.condition {
	color: $light-color;
	align-self: center;
	font-size: 12px;
	margin-left: 10px;
	max-width: 112px;
	//max-width: 120px; reduced for delete icon
}
.delete {
	align-self: center;
}
.deleteIcon {
	height: 20px;
	width: 16px;
	margin: -4px 0 0 0px;
	cursor: pointer;
	border-radius: 5px;
	background-color: $light-background-color;
	@extend %global-transition;
	path {
		fill: $grey;
	}
	&:hover,
	&:focus {
		path {
			fill: $primary-color;
		}
	}
}
