@import '../../styles/colors';
@import '../../styles/extends';
@import '../../styles/variables';

.fieldEditorContainer {
	padding: 10px;
	background-color: $background-color;
	border-radius: $border-radius;
	border: 1px solid $border-color;
}

.form {
	font-size: 14px;
	color: $text-color;
}
.fieldRow{
	display: flex;
	flex-grow: 1;
	margin-bottom: 8px;
}

.fieldLabel{
	flex-grow: 1;
	margin-bottom: 0;
	padding-bottom: 0;
	align-items: center !important;
	display: flex;
	margin-right: 10px;
}

.fieldInput{

}

.fieldNameErrMsg {
	margin: 0 0 5px 84px;
	font-size: 10px;
	white-space: pre;
}
