@import '../../styles/colors';
@import '../../styles/extends';
@import '../../styles/colors';
@import '../../styles/variables';

.container {
	width: 100%;
	padding: 50px;
}

.topContainer {
	display: flex;
	justify-content: space-between;
}
.syncButton {
	height: 40px;
}

.tableContainer{
	background-color: #fff;
	border: 1px solid $border-color;
	border-radius: $border-radius;
	overflow: hidden;
}

.title {
	color: $text-color;
	font-size: 30px;
	margin-bottom: 40px;
}

.table {
	color: $text-color;
	width: 100%;
	text-align: left;
	//filter: drop-shadow(0px 2px 5px $input-box-border);
	//-webkit-filter: none;
	//margin-bottom: 30px;
}

.tr {
	border-bottom: 1px solid $border-color;
	&:last-child {
		border-bottom: none;
	}
	&:first-child {
		border-top: 1px solid $border-color;
	}
}
thead .tr:first-child {
	border: none
}

.th {
	font-weight: bold;
	font-size: 15px;
	padding: 15px;
	text-align: center;
}

.td {
	// overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-size: 15px;
	padding: 15px;
	text-align: center;

	&:nth-of-type(4) {
		color: $primary-color;
	}
}

.textLeft {
	text-align: left;
}

.minusIco {
	width: 18px;
	cursor: pointer;
	position: relative;
	top: 5px;

	&:hover,
	&:focus {
		path {
			@extend %global-transition;
			fill: darken($error-color, 10%);
		}
	}
}

.link {
	@extend %global-transition;
	color: $primary-color;

	&:hover,
	&:focus,
	&:active,
	&:visited {
		color: $text-color;
	}
}

.dropbtn {
	height: 0;
	width: 0;
	border: none;
	border-top: 5px solid #000000;
	border-left: 5px solid rgba(0, 0, 0, 0);
	border-right: 5px solid rgba(0, 0, 0, 0);
}

.dropdown {
	position: relative;
	display: inline-block;
}

.hideDropdown {
	display: none;
}

.dropdownContent {
	right: -110px;
	top: -2px;
	display: none;
	position: absolute;
	background-color: #f9f9f9;
	min-width: 130px;
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
	z-index: 1;
}

.upperDropdownContent {
	right: -110px;
	display: none;
	position: absolute;
	background-color: #f9f9f9;
	min-width: 140px;
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
	z-index: 1;
	bottom: 22px;
}

.dropdownContent span {
	color: black;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
}

.upperDropdownContent span {
	color: black;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
}

.upperDropdownContent span:hover {
	background-color: #f1f1f1;
	cursor: pointer;
}

.dropdownContent span:hover {
	background-color: #f1f1f1;
	cursor: pointer;
}

.dropdown:hover .dropdownContent {
	display: block;
}

.dropdown:hover .upperDropdownContent {
	display: block;
}

.dropbtn:hover {
	cursor: pointer;
}
