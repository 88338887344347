@import '../../styles/colors';
@import '../../styles/extends';

.inputWrapper {
	width: 100%;
	position: relative;

	::placeholder {
		color: $light-color !important;
	}
}

.inputWrapperTemplate {
	::placeholder {
		color: $text-color;
	}
}

.input {
	position: relative;
	border: none;
	outline: none;
	width: 100%;
	border: 1px solid $grey;
	padding: 12px;
	font-size: 15px;
	color: $text-color;
	@extend %secondary-font;
	@extend %global-transition;

	&:focus,
	&:active {
		border-color: $primary-color;
	}
}

.textarea {
	resize: none;
}

.error {
	position: absolute;
	width: 100%;
	top: 34px;
	left: 0;
	font-size: 12px;
	color: $error-color;
	visibility: hidden;
}

.show {
	visibility: initial;
}

.primary {
	border-radius: 30px;
	padding: 18px 35px 18px 65px;
	font-weight: 300;
}

.icon {
	position: absolute;
	top: 18px;
	left: 28px;
	width: 23px;
	height: 20px;
	z-index: 2;
}

.errorPrimary {
	top: 60px;
	left: 0;
	text-align: center;
}

.secondary {
	border-radius: 5px;
	border: 1px solid $border-color;
	filter: drop-shadow(0px 2px 5px $border-color);
	-webkit-filter: none;
	padding: 12px;
	font-size: 15px;
}

.errorSecondary {
	top: 52px;
	left: 15px;
	text-align: left;
}

.ternary {
	padding: 12px;
	background-color: transparent;
	border: 1px solid $border-color;
	border-radius: 5px;
}

.errorTernary {
	top: 40px;
	left: 8px;
	text-align: left;
}

.template {
	padding: 6px;
	font-size: 9px;
	border: none;
	border-radius: 3px;
	text-align: center;
	box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
}
