@import '../../styles/colors';
@import '../../styles/extends';

.template {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
	padding: 30px;
	color: white;
	background-size: cover;
	background: grey url('../../assets/images/TemplateBg.png') center;
	text-align: center;
	filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.071));
	-webkit-filter: none;
	background-size: cover;
}

.templateImage {
	display: flex;
	align-items: center;
}

.video {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
	border-radius: 10px;
}

.backButton {
	position: absolute;
	top: 12px;
	left: 8px;
	height: 14px;
	cursor: pointer;
	z-index: 1;
	@extend %global-transition;

	path {
		fill: inherit;
	}

	&:hover,
	&:focus,
	&:active {
		path {
			@extend %global-transition;
		}
	}
}

.templateCol {
	flex: 1;
	margin-left: 145px;
}

.image {
	width: 150px;
	height: 97%;
	border-radius: 0;
	position: absolute;
	top: 3px;
	left: 3px;
	object-fit: cover;
}

.imageTemplate {
	//width: initial;
}

.priceBubble {
	position: absolute;
	top: 140px;
	left: 89px;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background-color: $text-color;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.priceLabel {
	font-size: 7px;
	display: block;
	margin-bottom: 2px;
}

.price {
	font-size: 9px;
	font-weight: bold;
}

.titleWrapper {
	position: absolute;
	top: 0;
	left: 0;
}

.title {
	font-size: 14px;
	color: $light-color;
	position: relative;
	top: -40px;
}

.templateRow {
	width: 100%;
}

.question {
	font-size: 18px;
	color: $text-color;
	width: 100%;
	margin-bottom: 12px;
	font-weight: bold;
	line-height: 20px;
}

.questionImage {
	text-align: left;
	margin-bottom: 8px;
}

.buttonsContainer {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.buttonWrapper {
	margin-top: 5px;
	margin-right: 10px;
	z-index: 3;

	&:last-of-type {
		margin-right: 0;
	}
}

.buttonWrapperSmall {
	margin-right: 5px;
}

.flexCol {
	flex-direction: column;
	justify-content: center;
}

.richText {
	flex: 1;
	font-size: 10px;
	color: $text-color;
	text-align: left;
	line-height: 12px;
	margin-bottom: 5px;

	strong {
		font-weight: bold;
	}

	em {
		font-style: italic;
	}

	ul {
		padding-left: 0;

		li {
			padding-left: 10px;
			list-style-type: disc;
			list-style-position: inside;
		}
	}

	ol {
		padding-left: 0;

		li {
			padding-left: 10px;
			list-style-type: decimal;
			list-style-position: inside;
		}
	}

	li::before {
		display: none;
	}
}
.richTextTextScene {
	flex: 1;
	font-size: 10px;
	color: $text-color;
	line-height: 12px;
	margin-bottom: 5px;

	strong {
		font-weight: bold;
	}

	em {
		font-style: italic;
	}

	ul {
		padding-left: 0;

		li {
			padding-left: 10px;
			list-style-type: disc;
			list-style-position: inside;
		}
	}

	ol {
		padding-left: 0;

		li {
			padding-left: 10px;
			list-style-type: decimal;
			list-style-position: inside;
		}
	}

	li::before {
		display: none;
	}
}
.lottie {
	width: 200px;
	height: 200px;
	position: relative;
	left: 50px;
}
