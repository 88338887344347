@import '../../styles/colors';
@import '../../styles/extends';
@import '../../styles/variables';

.inputLabel {
	width: 105px;
	height: 70px;
	background-color: $bg-gray;
	display: flex;
	flex-direction: column;
	border-radius: $border-radius;
	justify-content: center;
	align-items: center;
	@extend %global-transition;
	cursor: pointer;
	border: 2px dashed $border-color;


	&:hover,
	&:focus,
	&:active {
		background-color: $bg-gray;
		svg {
			path {
				@extend %global-transition;
				fill: $primary-color;
			}
		}
		span {
			@extend %global-transition;
			color: $primary-color;
		}
	}
}

.icon {
	width: 42px;
}

.span {
	font-size: 12px;
	line-height: 14px;
	color: black;
	text-transform: uppercase;
	margin-top: 6px;
}

.imagePreview {
	width: 105px;
	height: 70px;
	border-radius: 10px;
	object-fit: cover;

	&:hover,
	&:focus,
	&:active {
		border: 1px solid $primary-color;
	}
}
