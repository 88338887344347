@import '../../styles/colors';

$height: 17px;

.switch {
	position: relative;
	display: inline-block;
	width: calc(#{$height} * 2);
	height: $height;
	margin: 0 8px;

	&.singleChoice {
		.slider:before {
			background-color: $grey;
		}
		.slider.checked:before{
			background-color: $primary-color;
		}
	}
}

.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.disabled {
	&:before {
		background-color: gray !important;
	}
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: $bg-gray;
	transition: 0.4s;
}

.slider:before {
	position: absolute;
	content: '';
	height: $height;
	width: $height;
	background-color: $primary-color;
	transition: 0.4s;
	left: 0;
}

input:checked + .slider {
	background-color: $bg-gray;
}

input:focus + .slider {
	box-shadow: 0 0 1px $bg-gray;
}

input:checked + .slider:before {
	transform: translateX($height);
}

/* Rounded sliders */
.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}

.checked:before {
	background-color: $primary-color;
}

.singleChoice{
	padding-left: 0;
	margin-left: 0;
}
