@import '../../styles/colors';
@import '../../styles/variables';

.title {
  color: $text-color;
  font-size: $fs-h4;
  font-weight: bold;
}
.subtitle {
  color: $text-color;
  font-size: $fs-small;
}
.singleBtn {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.btnRow {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
