$global-navbar-height-mobile: 80px;
$global-navbar-height-tablet: 80px;
$global-navbar-height-desktop: 80px;
$global-sidebar-width-desktop: 330px;

// FONT SIZES
$fs-h1: 3rem;
$fs-h2: 2.3rem;
$fs-h3: 1.85rem;
$fs-h4: 1.25rem;
$fs-base: 1rem;
$fs-small: .8rem;

$fs-huge: 3.3rem;
$fs-big: 2.8rem;

// MISCELLANEOUS
$border-radius: 4px;
$icon-size: 22px;

// Z-indexes
$z-navbar: 10;
$z-stickyButtons: 20;
$z-canvas: 50;
$z-title: 100;
$z-modal: 200;
$z-tooltip: 250;
