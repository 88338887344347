@import '../../styles/variables';
@import '../../styles/mixins';

.overlay {
	position: fixed;
	z-index: $z-modal;
	background-color: rgba(1, 1, 1, 0.2);
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.modal {
	min-width: 200px;
	min-height: 200px;
	background-color: white;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
	border-radius: 5px;
	@include animation-fade-in(0.2s);
}
