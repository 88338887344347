@import '../../styles/colors';
@import '../../styles/variables';

.wrapper {
	position: relative;
	// top: 30px;
	margin-top: 10px;
}

.checkboxLabel {
	display: inline-block;
	font-size: 15px;
	color: $text-color;
	margin-bottom: 10px;
}

.codeEditor {
	border: 1px solid $input-box-border;
	max-width: 230px;
	word-wrap: break-word;
	font-size: $fs-base;
}
