@import '../../styles/colors';
@import '../../styles/extends';

.button {
	border: none;
	outline: none;
	color: white;
	font-weight: 700;
	@extend %secondary-font;
	@extend %global-transition;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.primary {
	background-color: $primary-color;
	font-size: 18px;
	border-radius: 30px;
	padding: 20px 35px;

	&:hover,
	&:focus,
	&:active {
		background-color: darken($primary-color, 10%);
	}
}

.accent {
	background-color: $accent-color;

	&:hover,
	&:focus,
	&:active {
		background-color: darken($accent-color, 10%);
	}
}

.secondary {
	background-color: $text-color;
	font-size: 9px;
	padding: 8px;
	border-radius: 3px;
	min-width: 85px;
	min-height: 26px;

	/* &:hover,
	&:focus {
		filter: brightness(80%);
	} */
}

.disabled {  // used in Canvas.js
	background-color: $light-color;
	font-size: 18px;
	border-radius: 30px;
	padding: 20px 35px;
	cursor: not-allowed !important;
}

.icon {
	width: 18px;
	margin-right: 15px;
}

.small {
	padding: 10px 33px;
	font-size: 15px;
	min-width: 100px;
	border-radius: 30px;
}

.outline{
	background-color: transparent;
	border: 1px solid $text-color;
	color: $text-color;
	&:hover{
		color: $base-white-color;
	}
}
.buttonContainer{
	height: 50px;
	min-width: 70px;
	position: relative;
	z-index: 100;
}
