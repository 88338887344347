@import '../../styles/colors';
@import '../../styles/extends';
@import '../../styles/media';
@import '../../styles/variables';

.container {
	display: flex;
	height: 100vh;
	//background-image: url('../../assets/images/LoginGrafik.png');
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	button {
		min-width: 340px;
		font-size: 18px;
		font-weight: 300;
	}
}

.left {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	//background-image: url('../../assets/images/clickmaster-green.png');
	background-image: url('../../assets/images/login-bg.png');
	background-position: bottom center;
	background-size: 70%;
	background-repeat: no-repeat;
	background-color: #edd7ac;
}

.textWrapper {
	position: absolute;
	top: 13%;
	text-align: center;
	max-width: 600px;
}

.title {
	font-size: $fs-big;
	color: $black;
	font-weight: 300;
	margin-right: 50px;
	margin-left: 50px;
	text-align: center;

}

.subtitle {
	font-size: 22px;
	color: $text-color;
	font-weight: 300;
	line-height: 26px;
	text-align: center;
}

.buttonWrapper {
	//margin-top: 13%;
}

.logo {
	width: 265px;
	height: 130px;
}

.logoPortrait{
	width: 265px;
	margin-bottom: 20px;
	height: auto;
	//rect {
	//	fill: $accent-color;
	//}
	//g > *{
	//	fill: red;
	//}
	//path {
	//	fill: $text-color;
	//}
}

.formContainer {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	form{
		max-width: 500px;
		display: flex;

		flex-direction: column;
		align-items: center;
	}
}

.inputWrapper {
	margin-top: 20px;
	min-width: 340px;
}

.error {
	position: absolute;
	top: 23px;
	left: 5px;
	font-size: 12px;
	color: $error-color;
	visibility: hidden;
}

.submitWrapper {
	margin-top: 20px;
	margin-bottom: 20px;
}

.noAccountContainer{
	position: absolute;
	bottom: 25px;
	text-align: center;
}


.noAccountLabel{
	font-size: $fs-h4;
	font-weight: 300;
	margin: 40px 0 15px 0
}
.link {
	color: $accent-color;
	font-weight: 300;
}
.forgotPasswordlink {
	font-size: $fs-base;
	text-decoration: none;
	color: $primary-color;
	font-weight: 300;
	@extend %global-transition;
	margin-bottom: 10px;
	width: 100%;
	display: block;
	text-align: center;

	&:hover,
	&:focus,
	&:active {
		color: darken($primary-color, 10);
	}
}

.warning {
	font-size: 15px;
	color: $error-color;
	//margin-top: 15px;
	margin-bottom: 0;
}

.success {
	color: $primary-color;
}

.mobileOnly {
	display: none;
}

@include tablet-down {
	.container {
		flex-direction: column;
		background-position: right 25% bottom 15%;
	}

	.left {
		display: none;
	}

	.mobileOnly {
		display: initial;
	}

	.logoPortrait {
		display: initial;
	}
}

// Register

.checkboxWrapper {
	position: relative;
	margin-top: 20px;
	max-width: 340px;
	display: flex;
	align-items: center;
	margin-left: 5px;
	text-align: center;
}

.checkboxLabel {
	display: inline-block;
	font-size: 15px;
	color: $text-color;
	font-weight: 300;
	margin-left: 8px;
	line-height: 18px;
	a{
		color: $accent-color
	}
}
