@import '../../styles/colors';
@import '../../styles/extends';
@import '../../styles/variables';

.box {
	padding: 0 0 10px;
	display: flex;
	flex-direction: column;
}
.generalWrapper {
	display: flex;
	align-content: center;
	flex-direction: row;
	margin: 10px 0;
}

.ownerLabel {
	display: block;
	font-size: 12px;
	margin-top: 5px;
	color: $text-color;
}
.name {
	display: block;
	font-size: 12px;
	color: $text-color;
	border: 1px solid $border-color;
	padding: 10px;
	border-radius: $border-radius;
	flex-grow: 1;
	&:first-letter {
		text-transform: capitalize;
	}
}

.icon {
	cursor: pointer;
	margin-left: 10px;
	margin-bottom: 1px;
	width: 18px;
	height: auto;
	path {
		@extend %global-transition;
	}

	&:hover,
	&:active,
	&:focus {
		path {
			fill: $primary-color;
		}
	}
}

.instructions {
	font-size: 10px;
	margin: 10px 0;
}
.nameEntryInput {
	border-radius: $border-radius;
}
