@import '../../styles/extends';
@import '../../styles/colors';
@import '../../styles/variables';

.wrapper {
	position: relative;
	user-select: none;

	&:hover,
	&:focus,
	&:active {
		outline: none;
	}
	&.open{
		//padding-bottom: 50px;
	}
}

.select {
	position: relative;
	display: flex;
	flex-direction: column;
}

.ternarySelect {
	border: 1px solid $border-color;
	border-radius: $border-radius;
}

.trigger {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 6px 17px;
	padding-right: 0;
	font-size: 16px;
	color: #9b9b9b;
	//min-height: 49px;
	background: white;
	cursor: pointer;

	span {
		padding-right: 15px;
	}
}

.ternaryTrigger {
	padding: 12px;
	font-size: 15px;
	font-weight: 400;
	color: $text-color;
	border-radius: $border-radius;
}

.disabledTrigger {
	color: #9b9b9b;
}

.options {
	position: absolute;
	display: block;
	top: 55px;
	right: 0;
	min-height: 112px;
	min-width: 150px;
	overflow: auto;
	filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.071));
	-webkit-filter: none;
	background-color: white;
	border-top: 0;
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
	z-index: $z-navbar;
	@extend %global-transition;
}

.ternaryOptions {
	top: 115%;
	left: 0;
	border-radius: $border-radius;
	background-color: $light-background-color;
	max-height: 128px;
	min-height: initial;
	border: 1px solid $light-color;
}

.noScroll {
	max-height: 178px;
}

.open .options {
	opacity: 1;
	visibility: visible;
	pointer-events: all;
}

.option {
	position: relative;
	display: block;
	padding: 20px 25px;
	font-size: 16px;
	color: #9b9b9b;
	cursor: pointer;
	@extend %global-transition;

	&:hover {
		cursor: pointer;
		background-color: #f6f7f9;
		color: $primary-color;
	}
}

.ternaryOption {
	padding: 10px;
	font-size: 15px;
	font-weight: 400;
	color: $light-color;

	&:hover {
		cursor: pointer;
		background-color: #f6f7f9;
		color: $text-color;
	}
}

.arrow {
	position: relative;
	height: 8px;
	width: 8px;

	&::before,
	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		width: 2px;
		height: 100%;
		@extend %global-transition;
		background-color: #9b9b9b;
	}

	&::before {
		left: -2px;
		transform: rotate(-45deg);
	}

	&::after {
		left: 2px;
		transform: (rotate(45deg));
	}
}

.open .arrow::before {
	transform: rotate(45deg);
}

.open .arrow::after {
	transform: rotate(-45deg);
}
