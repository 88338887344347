@import '../../styles/colors';
@import '../../styles/variables';

.container {
  width: 100%;
  padding: 50px;
}

.title {
  margin: 50px;
  text-align: center;
}

.emailContainer{
  width: 90%;
  max-width: 450px;
  background-color: white;
  border-radius: $border-radius;
  padding: 20px;
  border: 1px solid $border-color;
  margin-left: auto;
  margin-right: auto;
}

.inputLabel {
  color: $text-color;
  font-size: $fs-base;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.btn {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
