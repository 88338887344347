@import '../../styles/colors';
@import '../../styles/extends';
@import '../../styles/variables';

.inputGroupDescription {
	padding: 15px 0;
	display: flex;
	flex-direction: column;
}

.box {
	padding: 0 0 10px;
	display: flex;
	flex-direction: column;
}

.icon {
	cursor: pointer;
	margin-left: 15px;
	margin-bottom: 1px;
	width: 18px;
	height: 18px;
	path {
		@extend %global-transition;
	}

	&:hover,
	&:active,
	&:focus {
		path {
			fill: $primary-color;
		}
	}
}

.minusIcon {
	cursor: pointer;
	margin-left: 8px;
	width: 20px;
	height: 22px;

	&:hover,
	&:focus {
		path {
			@extend %global-transition;
			fill: darken($primary-color, 10%);
		}
	}
}

.plusIcon {
	cursor: pointer;
	margin-left: 19px;
	path {fill: $primary-color;}
	height: 20px;

	&:hover,
	&:focus {
		path {
			@extend %global-transition;
			fill: darken($primary-color, 10%);
		}
	}
}

.addFieldInputGroup {
	padding: 10px 0;
	display: flex;
	align-items: center;
}
.addFieldLabel {
	font-size: 12px;
	color: $text-color;
}
.fieldRow {
	display: flex;
	align-items: center;
	border-bottom: 1px dotted $grey;
	//&:last-child{
	//	border-bottom: none;
	//}
}
.descriptionLabel{
	margin-top: 0;
	margin-bottom: 10px;
	font-size: 12px;
	color: $text-color;
}

.label {
	flex-grow: 1;
	font-size: 15px;
	margin: 12px 0;
	color: $text-color;
}

.inputGroup {
	padding: 15px 0;
	display: flex;
	align-items: center;
	input[type='checkbox'] {
		margin-right: 10px;
	}
}

.inputLabel {
	font-size: 14px;
}

.inputGroupBackButton {
	padding: 15px 40px;
	display: flex;
	flex-direction: column;
	position: relative;
}

.checkboxWrapper {
	display: flex;
	align-items: center;
}

.checkboxLabel {
	display: inline-block;
	font-size: 15px;
	color: $text-color;
	margin-left: 5px;
}

.lightLabel {
	margin-bottom: 8px;
	color: $light-color;
}
