@import '../../styles/colors';
@import '../../styles/extends';

.inputGroup {
	padding: 15px 0px;
	display: flex;
	flex-direction: column;
	position: relative;
}

.label {
	font-size: 12px;
	margin-bottom: 14px;
	color: $text-color;
}

.imageRow {
	display: flex;
}

.condition {
	color: $light-color;
	align-self: center;
	font-size: 12px;
	margin-left: 10px;
	max-width: 120px;
}

.addVideoLabel {
	text-align: left;
	font: normal normal bold 15px/13px Ubuntu;
	letter-spacing: 0px;
	color: #4d4f5c;
	opacity: 1;
	margin-bottom: 3%;
}

.inputToggle {
	margin-bottom: 10px;
	display: flex;
	position: relative;
	align-items: center;

	label {
		margin-bottom: 0px;
	}
}

.toggleLabels {
	color: var(--unnamed-color-a6a7ad);
	text-align: left;
	font: normal normal normal 15px/42px Ubuntu;
	letter-spacing: 0px;
	color: #a6a7ad;
	opacity: 1;
}

.toggleButtonsLabel {
	text-align: left;
	font: normal normal bold 15px/13px Ubuntu;
	letter-spacing: 0px;
	color: #4d4f5c;
	opacity: 1;
	margin-bottom: -2%;
}

.uploadingContainer {
	width: 100%;
	height: 70px;
	background: $bg-gray 0 0 no-repeat padding-box;
	border: 2px dashed #a6a7ad;
	border-radius: 8px;
	font-size: 12px;
	opacity: 1;
}

.uploadFileLabel {
	text-align: left;
	letter-spacing: 0;
	color: #a6a7ad;
	font-size: 12px;
	opacity: 1;
	padding: 10px;
}

.percentage {
	width: 250px;
	height: 70px;
	text-align: center;
	letter-spacing: 0;
	color: #a6a7ad;
	opacity: 1;
	padding: 10px;
}

.videoUploadedBox {
	width: 100%;
	min-height: 70px;
	background: $bg-gray;
	border: 2px dashed #a6a7ad;
	border-radius: 8px;
	opacity: 1;
	display: inherit;
	font-size: 12px;
}

.videoUploadedLabel {
	text-align: center;
	letter-spacing: 0;
	color: #3085ff;
	opacity: 1;
	padding: 20px;
	line-height: 20px;
	padding-right: 10px;
	max-width: 195px;
	word-wrap: break-word;
}

.doneIcon {
	margin-top: 7.5%;
	width: 25px;
	height: 25px;
}
.titleBox {
	display: flex;
	justify-content: space-between;
}
.title {
	font-size: 25px;
	max-width: 210px;
	// max-width: 200px;
	// height: 31px;
	line-height: 30px;
	word-wrap: break-word;
}
.delete {
	align-self: center;
}
.deleteIcon {
	height: 20px;
	width: 16px;
	margin: -4px 0 0 0px;
	cursor: pointer;
	border-radius: 5px;
	background-color: $light-background-color;
	@extend %global-transition;
	path {
		fill: $grey;
	}
	&:hover,
	&:focus {
		path {
			fill: $primary-color;
		}
	}
}
