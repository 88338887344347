@import '../../styles/colors';
@import '../../styles/extends';

.template {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	height: 100%;
	width: 100%;
	padding: 30px;
	background: grey;
	color: white;
	background-image: url('../../assets/images/TemplateBg.png');
	background-size: cover;
	background-position: center;
	text-align: center;
	filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.071));
	-webkit-filter: none;

	button {
		padding: 5px 10px;
		min-height: 15px;
		min-width: 100px;
	}
}

.backButton {
	position: absolute;
	top: 12px;
	left: 8px;
	height: 14px;
	cursor: pointer;
	z-index: 1;

	path {
		fill: inherit;
	}

	&:hover,
	&:focus,
	&:active {
		path {
			@extend %global-transition;
		}
	}
}

.titleWrapper {
	position: absolute;
	top: 0;
	left: 0;
}

.title {
	font-size: 14px;
	color: $light-color;
	position: relative;
	top: -40px;
}

.templateRow {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.question {
	font-size: 18px;
	color: $text-color;
	width: 100%;
	font-weight: bold;
	line-height: 22px;
}

.inputRow {
	display: flex;
	margin-top: -3px;
}

.inputWrapper {
	width: 100%;
}

.margin {
	margin-left: 8.9px;
}

.checkboxContainer {
	position: relative;
	width: 100%;
}

.checkboxWrapper {
	display: flex;
	align-items: center;
	margin-top: 4px;

	input {
		margin-left: 0;
		border: none;
	}
}

.checkboxLink {
	display: inline-block;
	font-size: 8px;
	color: $text-color;
	margin-left: 3px;
	margin-top: 5px;
	@extend %global-transition;
	text-decoration: none;
	text-align: left;
	line-height: 10px;

	p {
		strong {
			font-weight: bold;
		}
		em {
			font-style: italic;
		}
	}

	a {
		color: inherit;
	}

	&:hover,
	&:focus,
	&:active {
		color: $primary-color;
	}
}

.buttonsContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}

.buttonWrapper {
	margin-top: 12px;
	width: 100%;

	button {
		width: 100%;
	}
}

.flexCol {
	flex-direction: column;
	justify-content: center;
}

.error {
	width: 100%;
	font-size: 7px;
	color: $error-color;
	visibility: hidden;
	text-align: center;
	position: absolute;
	bottom: -6px;
}

.show {
	visibility: initial;
}

.warning {
	position: absolute;
	z-index: 2;
	height: 100%;
	width: 100%;
	padding: 30px;
	background: grey;
	color: $text-color;
	font-size: 9px;
	font-weight: bold;
	background-image: url('../../assets/images/TemplateBg.png');
	background-size: cover;
	background-position: center;
	text-align: center;
	filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.071));
	-webkit-filter: none;
	display: flex;
	align-items: center;
}
