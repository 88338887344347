@import '../../styles/colors';
@import '../../styles/extends';
@import '../../styles/variables';

.inputGroupDescription {
	padding: 15px 0px;
	display: flex;
	flex-direction: column;
}

.box {
	padding: 0px 0px 10px;
	display: flex;
	flex-direction: column;
}

.label {
	display: block;
	font-size: 12px;
	margin-top: 15px;
	margin-bottom: 10px;
	color: $text-color;
}

.inputGroup {
	padding: 15px 0px;
	display: flex;
	flex-direction: column;
	position: relative;
}

.zIndex {
	z-index: $z-navbar;
}

.placeholderLabel {
	display: block;
	font-size: 12px;
	margin: 5px 0 7px;
	color: $text-color;
}

.requiredField {
	margin: 20px 0 0;
	// height: 30px;
	font-size: 12px;
	color: $text-color;
}

.defaultAnswerLabel {
	display: block;
	font-size: 12px;
	margin-top: 15px;
	color: $text-color;
}

.otherInput {
	padding: 3px;
	outline: none;
	font-size: 14px;
	color: grey;
	background-color: transparent;
	border: 1px solid #a6a7ad;
	border-radius: 4px;
	width: 230px;
}
