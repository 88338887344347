@import "../../styles/variables";
.priceTableContainer {
  width: 100%;
  padding: 0 50px 80px;
  margin: 50px auto;
  max-width: 1000px;
  background-color: white;
}

.pageTitle {
  margin-top: 50px;
  text-align: center;
}
.subtitle{
  font-size: 18px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;
}
