@import '../../styles/colors';

.hr {
	border: 1px dotted $border-color;
	margin: 30px;

	&:first-of-type {
		display: none;
	}
}

.box {

	padding-left: 30px;
	padding-right: 30px;
}
