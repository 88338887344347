@import '../../styles/colors';
@import '../../styles/extends';

.inputLabel {
	width: 105px;
	height: 70px;
	background-color: rgba(166, 167, 173, 0.2);
	display: flex;
	flex-direction: column;
	border-radius: 10px;
	justify-content: center;
	align-items: center;
	@extend %global-transition;

	&:hover,
	&:focus,
	&:active {
		background-color: rgba(166, 167, 173, 0.3);
		svg {
			path {
				@extend %global-transition;
				fill: $primary-color;
			}
		}
	}
}

.icon {
	width: 42px;
}

.span {
	font-size: 12px;
	line-height: 14px;
	color: black;
	text-transform: uppercase;
	margin-top: 6px;
}

.imagePreview {
	width: 105px;
	height: 70px;
	border-radius: 10px;
	object-fit: cover;

	&:hover,
	&:focus,
	&:active {
		border: 1px solid $primary-color;
	}
}

.uploadBox {
	background-color: $bg-gray;
	border: 2px dashed #a6a7ad;
	border-radius: 8px;
	opacity: 1;
	min-height: 70px;
	display: flex;
	font-size: 12px;
	margin-top: 10px;
	width: 80%;
}

.uploadIcon {
	margin-top: 7%;
	margin-left: 7%;
}

.fileUpload {
	opacity: 0;
	cursor: pointer;
	width: auto;
	height: auto;
	min-height: 70px;
	position: absolute;
	left: 0;
}

.uploadWrapper {
	display: flex;
	align-items: flex-start;
	flex-direction: row;
	font-size: 12px;
}

.inputText {
	text-align: left;
	opacity: 1;
	padding: 20px;
	display: block;
}
.fileExtension {
	text-align: left;
	font: normal normal normal 15px/42px Ubuntu;
	letter-spacing: 0;
	color: #a6a7ad;
	opacity: 1;
	line-height: 1.5;
}
