@import '../../styles/colors';
@import '../../styles/extends';
@import '../../styles/variables';
@import '../../styles/media';

.container {
	flex: 1;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	position: relative;
}
.widgetContainer {
	flex: 1;
	display: flex;
	// justify-content: center;
	flex-direction: column;
	align-items: center;
	position: relative;
}

.containerPublic {
	height: 100vh;

	input[type='text'] {
		margin-top: 12px;
	}

	input[type='number'] {
		margin-top: 12px;
	}

	&.widget {
		flex-direction: initial !important;
	}
}

.projectTitle {
	position: absolute;
	z-index: $z-title;
	color: $text-color;
	font-size: 30px;
	top: 40px;
	left: 50px;
	cursor: pointer;
	@extend %global-transition;

	&:hover,
	&:focus,
	&:active {
		color: $primary-color;

		svg {
			path {
				@extend %global-transition;
				fill: $primary-color;
			}
		}
	}
}

.ico {
	width: 14px;
	height: 22px;
	margin-right: 20px;
}

.scene {
	position: relative;
	width: 350px;
	height: 200px;
	transform: scale(2);
}

.widgetScene {
	margin-top: 100px;
	position: relative;
	max-width: 800px;
	background-color: $base-white-color;
	padding: 30px;
	margin-bottom: 100px;
	h1 {
		margin: 30px 0;
	}
	p {
		margin: 20px 0;
	}
}

.buttonRow {
	display: flex;
	transform: translateY(150px);
	flex-direction: row;
	position: relative;
}

.widgetButtonRow {
	display: flex;
	flex-direction: row;
	position: fixed;
	bottom: 20px;
}

.buttonWrapper {
	margin-left: 15px;
}

@function strip-unit($number) {
	@if type-of($number) == 'number' and not unitless($number) {
		@return $number / ($number * 0 + 1);
	}

	@return $number;
}

@include mobile-down {
	.projectTitle {
		left: 15px;
	}

	.scene {
		//transform: scale(strip-unit(100% / 100));
		transform: scale(1);
	}

	.container {
		.scene {
			margin-top: 200px;
		}
	}

	.containerPublic {
		.scene {
			margin-top: 0;
		}
	}

	.buttonRow {
		transform: translateY(50px);
	}

	.buttonRow,
	.widgetButtonRow {
		flex-direction: column;
		align-items: center;

		button {
			margin-bottom: 15px;
		}
	}
}

// TODO: remove these rules after removing iframe
@media (max-width: 767px) {
	.scene {
		transform: scale(1.5);
	}

	.buttonRow {
		transform: translateY(70px);
	}
}

@media (max-width: 700px) {
	.scene {
		transform: scale(1.4);
	}

	.buttonRow {
		transform: translateY(60px);
	}
}

@media (max-width: 600px) {
	.scene {
		transform: scale(1.25);
	}

	.buttonRow {
		transform: translateY(50px);
	}
}

@media (max-width: 500px) {
	.scene {
		transform: scale(1.1);
	}
}

@media (max-width: 400px) {
	.scene {
		transform: scale(1);
	}
}

@media (max-width: 370px) {
	.container {
		width: 100%;

		.scene {
			transform: scale(0.85);
		}
	}
}

@media screen and (max-width: 850px) and (orientation: landscape) {
	.container {
		.scene {
			margin-top: 500px;
		}
	}

	.containerPublic {
		.scene {
			margin-top: 150px;
			margin-bottom: 150px;
		}
	}

	.container {
		.marginBottom {
			margin-bottom: 0;
		}
	}
}
