@import '../../styles/colors';
@import '../../styles/variables';
@import '../../styles/extends';

.sidebar {
	flex: 0 $global-sidebar-width-desktop;
	background-color: $light-background-color;
	filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.039));
	-webkit-filter: none;
	height: calc(100vh - #{$global-navbar-height-desktop});
}

.tabs {
	display: flex;
}

.tab {
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
	font-size: 16px;
	text-transform: uppercase;
	background-color: $background-color;
	cursor: pointer;
	border: 1px solid transparent;
	border-bottom-color: $border-color;

	&:first-child {
		border-left: 1px solid $border-color;
	}
}

.active {
	background-color: $light-background-color;
	border-color: $border-color;
	color: black;
	border-bottom-color: transparent;
	border-radius: $border-radius $border-radius 0 0;
}

.container {
	padding: 20px 0;
	height: calc(100vh - #{$global-navbar-height-desktop} - 50px);
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	border-left: 1px solid $border-color;
}
