@import '../../styles/colors';
@import '../../styles/variables';

.addSceneTabContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.title {
	color: $text-color;
	text-align: left;
	font-size: $fs-h3;
	margin-bottom: 10px;
	align-self: start;
	margin-left: 30px;
}

.description {
	font-size: 12px;
	margin-bottom: 25px;
	color: $text-color;
	align-self: start;
	margin-left: 30px;
}

.sceneTypeTitle {
	text-align: center;
	color: #b8d5ef;
	font-size: 15px;
	font-weight: bold;
	margin-bottom: 24px;
	margin-top: 9px;
}

.selectedTemplate {
	border: 3px solid $primary-color;
}

.image {
	width: 265px;
	height: 150px;
	cursor: pointer;
}
