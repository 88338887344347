@import '../../styles/variables';
@import '../../styles/colors';
@import '../../styles/extends';
@import '../../styles/media';

.navbar {
	position: relative;
	height: $global-navbar-height-desktop;
	display: flex;
	justify-content: space-between;
	align-items: center;
	filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.071));
	background-color: white;
	z-index: $z-navbar;
}

.logo {
	width: 220px;
	height: auto;
	margin-left: 40px;
	cursor: pointer;
}

.list {
	display: flex;
	position: relative;
}

.item {
	padding: 25px 15px 10px;
	margin-bottom: 15px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	&:last-child{
		margin-right: 30px;
	}
	@extend %global-transition;

	&:hover,
	&:focus,
	&:active {
		color: $primary-color;
	}
}

.active {
	color: $primary-color;
}

.userIcon {
	width: 32px;
	path {
		fill: $primary-color;
	}
}

@include tablet-down {
	.item {
		&:nth-of-type(1),
		&:nth-of-type(2),
		&:nth-of-type(3) {
			display: none;
		}
	}
}
