@import '../../styles/colors';
@import '../../styles/extends';
@import '../../styles/variables';

.selectLang {
  margin: 30px 30px 0;
}

.changeLangLabel {
  display: block;
  font-size: 12px;
  font-weight: 500;
  margin: 5px 0;
  color: $text-color;
}
